.facebook_likebox {
    //display: none;
    direction: ltr;
    line-height: 160%;
    color: #777777;
    margin: 0;
    font-size: 100%;
    font-family: Source,
        Arial,
        Helvetica,
        sans-serif;
    position: fixed;
    top: 200px;
    z-index: 100;
    background-color: rgba(59, 89, 152, 0.5);
    padding: 10px;
    border: 0 solid #133783;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    padding-left: 40px;
    border-width: 1px;
    border-left: 0;
    border-radius: 0 0 10px 0;
    left: -343px;

    @include media-query(mega) {
        display: block;
        left: -343px;
        top: 150px;
    }
}

.facebook-white {
    direction: ltr;
    line-height: 160%;
    color: #777777;
    margin: 0;
    padding: 0;
    border: 0px none;
    font-size: 100%;
    font-family: Source,
        Arial,
        Helvetica,
        sans-serif;
    position: absolute;
    width: 40px;
    height: 120px;
    background: #133783 center center no-repeat;
    cursor: pointer;
    top: -1px;
    right: -40px;
    border-radius: 0 10px 10px 0;
    box-shadow: 2px 0 2px rgba(0, 0, 0, 0.5);
    background-image: url(/static/images/icons/f-white-left.png);
}