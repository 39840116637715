/*
* Main navigation
*/

.site-nav {

    text-align: center;

    @include media-query(normal) {

        display: block;

    }

    >ul {

        padding: 0;

        @include media-query(normal) {

            display: flex;
            justify-content: center;

        }

        @include media-query(mega) {

            margin: 0;
            justify-content: flex-end;

        }

        >.active {

            background-color: $shuttle-grey;

            >a {

                color: #FFF;

            }

        }

        >.active>.arrow {

            color: #FFF;

        }

        >.active>.subnav>li>a {

            color: #FFF;

        }

        >li {

            list-style: none;
            text-align: center;
            position: relative;

            @include media-query(normal) {

                display: inline-block;
                position: static;

            }

            >a {

                color: $red;
                text-decoration: none;
                display: block;
                padding: 1rem;

                &:hover {

                    background-color: $red;
                    color: #FFF;

                }

                @include media-query(normal) {

                    display: inline-block;

                }

                @include media-query(mega) {

                    padding: 1.25rem .8125rem;

                }

                @include media-query(giga) {

                    padding: 1.25rem;

                }

            }

        }

        >li>.arrow {

            background-color: lighten($red, 20%);
            color: #FFF;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            font-size: .75rem;
            position: absolute;
            right: 7px;
            top: 7px;
            margin-left: 1rem;
            border: none;
            width: 42px;
            height: 42px;

            @include media-query(normal) {

                width: auto;
                height: auto;
                font-size: .75rem;
                background-color: transparent;
                color: $red;
                position: relative;
                top: 0;
                right: 1.1rem;
                display: inline-block;
                border: none;
                margin-left: 0;

            }

            &.-blue-arrow {

                background-color: lighten($dark-blue, 20%);

                @include media-query(normal) {

                    background-color: transparent;

                }

            }

        }

        >li>.arrow.-active-arrow {

            transform: rotate(180deg);
            border: 1px solid #FFF;

        }

        /**
        * Subnav
        */

        >li>.subnav {

            padding: 0;
            margin: 0;
            z-index: 1000;
            text-align: center;

            @include media-query(normal) {

                position: absolute;
                text-align: left;
                display: none;

            }

            >li {

                list-style: none;

            }

            >li>a {

                text-decoration: none;
                padding: 1em;
                display: block;
                transition: background-color .2s ease-in-out;
                color: $red;

            }

            >li>a:hover {

                background-color: rgba(0, 0, 0, 0.55);

            }

            >.active>a {

                background-color: rgba(0, 0, 0, 0.55);

            }

        }

    }

}

// Nav link hover colors
.site-nav>ul {
    >li:nth-child(2) {

        a:hover {

            background-color: $dark-blue;

        }

    }

    >li:nth-child(4) {

        a:hover {

            background-color: $dark-blue;

        }

    }

    >li:nth-child(6) {

        a:hover {

            background-color: $shuttle-grey;

        }

    }

    >li:nth-child(7) {

        a:hover {

            background-color: $shuttle-grey;

        }

    }

    >li:nth-child(8) {

        a:hover {

            background-color: $dark-blue;

        }

    }

}


// Subnav hover states
.site-nav>ul>li:nth-child(3):hover {

    background-color: $red;

    >a {

        color: #FFF;

    }

    .subnav {

        background-color: $red;

        @include media-query(normal) {

            display: block;

        }

        >li>a {

            color: #FFF;

        }

    }

}

.site-nav>ul>li:nth-child(4):hover {

    background-color: $dark-blue;

    >a {

        color: #FFF;

    }

    .subnav {

        background-color: $dark-blue;

        @include media-query(normal) {

            display: block;

        }

        >li>a {

            color: #FFF;

            &:hover {

                background-color: darken($dark-blue, 20%);

            }

        }

    }

}

.site-nav>ul>li:nth-child(5):hover {

    background-color: $red;

    >a {

        color: #FFF;

    }

    .subnav {

        background-color: $red;

        @include media-query(normal) {

            display: block;

        }

        >li>a {

            color: #FFF;

        }

    }

}

.site-nav>ul>li:hover {

    >.arrow {

        color: #FFF;

    }

}