.service-grid {

    >.service-grid-wrap>.contact {

        display: none;
        width: 100%;
        background-color: $dark-grey;
        color: #FFF;
        margin: 0;
        padding: 1rem;
        text-transform: uppercase;

        @include media-query(normal) {

            display: block;

        }

        >a {

            color: #FFF;

        }

    }

    >.service-grid-wrap>.grid {

        display: flex;
        flex-direction: column-reverse;

        @include media-query(normal) {

            display: flex;
            flex-wrap: wrap;
            flex-direction: row;

        }

        .box {

            text-decoration: none;
            color: #FFF;
            background-color: $dark-grey;
            display: block;

            @include media-query(micro) {

                display: flex;
                position: relative;

            }

            .title {

                font-size: 1rem;
                text-align: center;

                @include media-query(mega) {

                    font-size: 1.25rem;

                }

            }

            .title.-large {

                font-size: 1.5rem;

                @include media-query(normal) {

                    text-align: left;

                }

                @include media-query(mega) {

                    text-align: left;

                }

                @include media-query(tera) {

                    font-size: 2.375rem;
                    margin-bottom: .5rem;

                }

            }

            .subtitle {

                text-align: center;

            }

            .read-more {

                display: none;
                margin: auto;
                border: 1px solid #FFF;
                background-color: transparent;
                color: #FFF;
                padding: .5rem;
                transition: background-color .2s ease-in-out;

                &:hover {

                    color: $dark-grey;
                    background-color: #FFF;
                }

                @include media-query(micro) {

                    display: inline-block;

                }

            }

            .read-more.-large {

                display: block;
                text-decoration: none;
                max-width: 223px;
                margin-bottom: 1rem;

                @include media-query(normal) {

                    display: inline-block;
                    margin-bottom: 0;
                    padding: 1.25rem 2rem;
                    position: relative;
                    top: .75rem;
                    margin-right: 1rem;

                }

            }

            img {

                @include media-query(micro) {

                    width: 100%;

                }

            }

            &.-red-box {

                background-color: $red;

            }

            &.-grey-box {

                background-color: $shuttle-grey;

            }

            &.-dark-blue-box {

                background-color: $dark-blue;

            }

        }

        .box.-large {

            display: block;
            position: relative;

            @include media-query(normal) {

                display: flex;

            }

            .content {

                padding: .5rem;
                text-align: center;

                @include media-query(micro) {

                    padding: 1rem 6.5%;

                }

                @include media-query(normal) {

                    position: absolute;
                    bottom: 0;
                    left: 0;
                    text-align: left;

                }

                @include media-query(tera) {

                    padding: 2rem 6.5%;

                }

            }

            .content>.subtitle {

                display: none;
                text-align: left;

                @include media-query(mega) {

                    display: block;

                }

            }

            .content>.request-quotation-btn {

                background-color: $red;
                color: #FFF;
                display: inline-flex;
                justify-content: space-between;
                align-items: center;
                border: 0;
                text-align: left;
                margin: 0 auto 1rem auto;
                padding: .5rem;
                border: 1px solid transparent;
                transition: border .2s ease-in-out;
                text-decoration: none;
                position: absolute;
                top: 20%;
                left: 1rem;
                width: 223px;
                right: 0;

                &:hover {

                    border: 1px solid #FFF;

                }

                @include media-query(375px) {

                    top: 28%;

                }

                @include media-query(nano) {

                    top: 33%;

                }

                @include media-query(micro) {

                    top: 40%;

                }

                @include media-query(normal) {

                    margin: unset;
                    position: static;

                }

                .wrap {

                    width: 77%;

                }

                span {

                    display: block;

                }

                img {

                    margin-left: 1rem;
                    width: 23%;

                }

            }

        }

        .box>.overlay {

            @include media-query(micro) {

                opacity: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(#000000, .8);
                transition: opacity .2s ease-in-out;

            }

            &.-red-overlay {

                background-color: rgba($red, 0.8);

            }

            &.-grey-overlay {

                background-color: rgba($shuttle-grey, 0.8);

            }

            &.-dark-blue-overlay {

                background-color: rgba($dark-blue, 0.8);

            }

            .wrap {

                padding: .5rem;

                @include media-query(micro) {

                    padding: 1rem;
                    width: 100%;
                    text-align: center;

                }

            }

        }

        .box>.overlay.-visible {

            display: flex !important;

        }

        .box>.overlay.-static {

            display: block !important;
            opacity: .25;

        }

    }

    >.service-grid-wrap>.grid>.right {

        @include media-query(normal) {

            max-width: 50%;
            width: 50%;

        }

        >.flex {

            @include media-query(micro) {

                display: flex;
                flex-wrap: wrap;

            }

        }

        >.flex>.box {

            @include media-query(micro) {

                max-width: 50%;

            }

        }

    }

    >.service-grid-wrap>.grid>.left {

        @include media-query(micro) {

            display: flex;
            flex-wrap: wrap;

        }

        @include media-query(normal) {

            max-width: 50%;
            width: 50%;

        }

        .box {

            @include media-query(micro) {

                max-width: 50%;
                width: 50%;
                align-items: center;
                //flex: 1 0 50%;

            }

        }

    }

}

// Overlay hover

.service-grid>.service-grid-wrap>.grid .box:hover>.overlay {

    opacity: 1;

}