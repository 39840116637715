//
// Palette
//

$text-color: #333;
$link-color: #06c;

$red: #CD1631;
$tobasco: #aa1f19;

$blue: #0088cc;
$dark-blue: #0A44AB;

$dark-grey: #333333;
$deep-grey: #222222;
$mid-grey: #777777;
$shuttle-grey: #556270;

$pearl: #EBE7DB;