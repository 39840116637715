.contact-form {

    max-width: 464px;

}

.contact-form>.message {

    @include font-weight(semibold);

}

.contact-form>.message.error {

    color: $red;

}

.contact-form>.message.success {

    color: green;

}


.contact-form>form {

    .form-section {

        @include media-query(micro) {

            display: flex;
            justify-content: space-between;
            margin-bottom: 1rem;

        }

    }

    .form-section.-single-item {

        @include media-query(micro) {

            display: flex;
            justify-content: flex-end;

        }

    }

    .form-section>label {

        display: block;
        @include font-weight(semibold);
        padding: .5rem 0;

        >.asterisk {

            color: $red;

        }

    }

    .form-section>input {

        width: 100%;
        border: 1px solid $mid-grey;
        border-radius: 3px;
        padding: .5rem;

        @include media-query(micro) {

            width: 70%;

        }

    }

    .form-section>textarea {

        @extend input;
        height: 100px;

        @include media-query(micro) {

            height: 150px;

        }

    }

    .form-section>button {

        border: 0;
        border-radius: 3px;
        width: 100%;
        padding: .5rem;
        background-color: $shuttle-grey;
        color: #FFF;
        cursor: pointer;
        margin-top: .5rem;
        text-transform: uppercase;
        @include font-weight(semibold);

        &:hover {

            background-color: darken($shuttle-grey, 20%);

        }

        @include media-query(micro) {

            width: auto;

        }

    }

    >.error {

        color: $red;
        @include font-weight(semibold);
        display: block;
        text-align: left;
        margin-bottom: 1rem;

        @include media-query(micro) {

            text-align: right;

        }

    }

}