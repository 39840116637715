//
// Layout
//

// Float container
@mixin float-container {
    &::after {
        clear: both;
        content: "";
        display: block;
    }
}

// Page section wrapper
.page-wrap {
    @include float-container;

    margin: 0 auto;
    max-width: 1440px;
    padding: 0 1em;
    position: relative;
}

.page-wrap.-white {
    background-color: #FFF;
}

.page-wrap.-main-wrap {

    padding: 1em 1em 0 1em;
    margin-top: 1em;

    @include media-query(milli) {

        padding: 2em 2em 0 2em;
        margin-top: 2em;

    }

}

.page-wrap.-footer {

    padding: 0;

}

/*
* Wraps the entire page and all it's page wraps
*/
.site-container.-js-site-container {

    @include media-query(normal) {

        margin-top: 12.5rem;

    }

    @include media-query(mega) {

        margin-top: 6.5rem;

    }

}