.page-content {

    background-color: $pearl;
    padding: 1rem;

    @include media-query(micro) {

        padding: 1.5rem 2rem;

    }

    @include media-query(mega) {

        padding: 2rem;

    }

    >.block {

        margin-bottom: 1rem;

        .facebook-feed-wrap {
            margin: 1rem 0;
            max-width: 395px;

            @include media-query(nano) {
                float: right;
                margin: 1rem;
            }

            @include media-query(normal) {
                margin: 0 1rem 0 2rem;
            }
        }

        &:after {
            content: "";
            clear: both;
        }

    }

    >.block>a {

        text-decoration: none;

    }

    >.block>a>.heading:hover {

        color: $blue;

    }

    >.block .heading {

        color: $red;
        margin: 0;
        letter-spacing: -1px;
        @include font-weight(normal);

        @include media-query(mega) {

            font-size: 1.75rem;

        }

        &.-blue {

            color: $blue;

        }

        &.-capital {

            text-transform: uppercase;

        }

        &.-bold {

            @include font-weight(semibold);

        }

        &.-main {

            @include media-query(mega) {

                font-size: 2.375rem;

            }

        }

    }

    >.block .subheading {

        font-size: 1rem;

    }

    >.block>.wrap {

        @include media-query(normal) {

            display: flex;
            justify-content: space-between;

        }

    }

    >.block>.wrap.full-width-wrap {
        .text {
            width: 100%;
            max-width: 100%;
        }
    }

    >.block>.wrap>.text {

        @include media-query(normal) {

            max-width: 900px;

        }

        @include media-query(tera) {

            width: 900px;

        }

        &.-floated-text {
            @include media-query(nano) {
                float: left;
            }
        }

    }

    >.block>.wrap .image {
        margin: 1rem 0;

        @include media-query(normal) {

            max-width: 400px;
            margin-top: 1rem;
            margin-left: 2rem;

        }

        @include media-query(tera) {

            width: 400px;

        }

    }

    >.block>.wrap .image.-collage {

        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        >.section {

            display: flex;
            flex-wrap: wrap;

            @include media-query(normal) {

                align-content: flex-start;

            }

            >.pic {

                max-width: 48%;
                margin: 0 1% 0 1%;

                @include media-query(normal) {

                    width: 48%;

                }

            }

        }
    }

}

.page-content.-white {

    background-color: #FFF;

}


/*
* Styles for contact page
*/
.page-content.-contact-page {
    padding: 1rem 0;

    .heading.-main,
    .subheading {

        max-width: 250px;

    }

    .heading.-main {

        color: #000;
        @include font-weight(light);

    }

    .subheading {

        font-size: 1.4375rem;
        @include font-weight(normal);

    }

    .description {

        color: lighten($dark-grey, 15%);

    }

    .block.-contact {

        @include media-query(micro) {

            margin-bottom: 1.5rem;

        }

        @include media-query(milli) {

            margin-bottom: 2rem;

        }

    }

    .block>.address {

        >.address-line {

            display: block;
            font-style: normal;
            font-size: 1.1875rem;

        }

    }

    .block>.phone-numbers {

        .telephone,
        .mobile {

            font-size: 1.1875rem;

        }

        >.telephone>a {

            color: $text-color;

        }

        >.mobile>a {

            color: $text-color;

        }

    }

}

/*
* Styles for article pages
*/

.main.-flex {

    @include media-query(normal) {

        display: flex;
        justify-content: space-between;

    }

}

.main.-flex>.page-content.-article-page-content {

    width: 100%;

}

.-article-page-content {

    padding: 0 !important;
    background-color: #FFF;

    @include media-query(mega) {

        padding: 0 0 2rem 0 !important;

    }

}

.-article-page-content .article-block-subheading {

    font-size: 1.25rem;
    @include font-weight(normal);

    @include media-query(micro) {

        margin-top: 2rem;

    }
}

.-article-page-content .article-block-subheading.-freeindex-link {

    @include media-query(normal) {

        margin-bottom: 2rem;

    }

    @include media-query(mega) {

        margin-bottom: 0;

    }

}

.-article-page-content>.article-heading {

    color: #FFF;
    @include font-weight(normal);
    background-color: #000;
    font-size: 1.5rem;
    padding: .5rem;
    margin-bottom: 1rem;

    @include media-query(micro) {

        padding: 1rem .5rem;
        margin-bottom: 1.5rem;

    }

    @include media-query(milli) {

        padding: 1rem;

    }

    @include media-query(normal) {

        margin-bottom: 2rem;
        margin-top: 0;

    }

    @include media-query(mega) {

        padding: 1.5rem 1rem;

    }

}

.-article-page-content>.article-heading.-bottom {

    background-color: $red;
    color: #FFF;
    @include font-weight(light);
    margin-top: -6px;

    @include media-query(normal) {

        font-size: 2.25rem;

    }

}

.-article-page-content>.article-block.-split {

    @include media-query(micro) {

        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

    }

}

.-article-page-content>.article-block {

    >.block-left {

        @include media-query(micro) {

            max-width: 50%;

        }

    }

    >.block-right {

        @include media-query(micro) {

            max-width: 50%;
            padding-left: 1.25rem;

        }

    }

    .image>img {

        @include media-query(micro) {

            max-height: 300px;

        }

    }

    .text {

        >p {

            @include media-query(micro) {

                margin-top: 0;

            }

        }

    }

    .-red {

        color: $red;

    }

    .-semibold {

        font-weight: 600 !important;

    }

}

.-article-page-content>.article-block .freeindex-wrapper {

    overflow-x: auto;

}

.-article-page-content>.article-block.-image-first>.lightbox-image-container {

    position: relative;
    display: block;

    >.featured-image {

        position: absolute;
        top: 0;
        right: 0;

    }

}

.-article-page-content>.article-block>.article-image-gallery {

    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    >.gallery-thumbnail {

        margin-bottom: 1.38%;

        @include media-query(micro) {

            max-width: 48%;
            margin-right: 1.38%;

        }

        @include media-query(normal) {

            max-width: 170px;
            max-height: 127px;
            overflow: hidden;

        }

    }

    >.gallery-thumbnail>img {

        //max-height: 170px;
        //max-width: 170px;

    }

}

.page-content-side-content {
    max-width: 395px;

    @include media-query(768px) {
        float: right;
        margin-left: 20px;
    }

    .facebook-feed-wrap {
        margin-bottom: 20px;
        text-align: center;
    }

    .full-width-image {
        margin-bottom: 16px;
    }

    .partner-grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        >a {
            width: 100%;
            max-width: 47%;
            margin-bottom: 20px;

            @include media-query(micro) {
                max-width: 47.5%;
            }

            @include media-query(768px) {
                max-width: 187px;
                max-height: 187px;
            }
        }
    }
}