.site-footer {

    width: 100%;
    padding: 0 1em 2em 1em;
    background-color: #FFF;
    text-align: center;

    @include media-query(milli) {

        padding: 0 2em 2em 2em;

    }

}

.site-footer>.footer-wrap {

    background-color: $deep-grey;
    color: #FFF;

    >.heading {

        @include font-weight(light);
        padding: 1rem;
        margin: 0;

        @include media-query(micro) {

            padding: 1rem;

        }

    }

    >.text {

        padding: .5rem;
        margin: 0;

        @include media-query(micro) {

            padding: .5rem 1rem;

        }

        @include media-query(normal) {

            padding: .5rem 2rem;

        }

    }

    >.copyright {

        background-color: $tobasco;
        color: #FFF;
        padding: 1rem;
        font-size: .85rem;
        width: calc(100% - 2em);
        margin: 1rem auto 0 auto;

        @include media-query(nano) {
            display: flex;
            justify-content: space-between;
            flex-direction: row-reverse;
            align-items: center;
        }

        @include media-query(micro) {
            justify-content: center;
        }

        @include media-query(mega) {

            margin: 2rem auto 0 auto;

        }

        >.facebook-link {
            @include media-query(micro) {
                margin-left: 1rem;
            }

            >img {
                width: 40px;
                height: 40px;
            }
        }

    }

}

/*
* Pre-footer section
*/

.pre-footer-section {

    width: 100%;
    background-color: $deep-grey;
    color: #FFF;

    >.wrap {

        @include media-query(micro) {

            display: flex;
            flex-wrap: wrap;

        }

        @include media-query(mega) {

            flex-wrap: nowrap;

        }

    }

    >.wrap>.cell {

        text-decoration: none;
        color: #FFF;

        @include media-query(micro) {

            max-width: 50%;
            width: 50%;

        }

        @include media-query(normal) {

            max-width: 25%;
            width: 25%;

        }

        @include media-query(mega) {

            max-width: none;
            width: 20%;

        }

        >.heading {

            padding: 1.5rem;
            margin: 0;
            @include font-weight(light);

        }

        >.heading.-light {

            @include media-query(micro) {

                background-color: lighten($deep-grey, 2%);

            }

        }

        >.text {

            padding: 1.5rem;
            margin: 0;
            font-size: .9rem;

            @include media-query(normal) {

                overflow: hidden;
                text-overflow: ellipsis;
                position: relative;
                min-height: 143px;

            }

        }

        >.text.-light {

            @include media-query(micro) {

                background-color: lighten($deep-grey, 2%);

            }

        }

        >.text>.read-more {

            color: $red;
            display: inline-block;
            padding: 1rem;
            width: 100%;
            padding: 1rem 0;
            text-transform: uppercase;

            &:hover {

                color: $blue;

            }

            @include media-query(normal) {

                position: absolute;
                bottom: 0;
                left: 1.5rem;

            }

        }

    }

    >.wrap>.cell:nth-child(odd)>.image>img {

        border-bottom: 1.5rem solid $red;

    }

    >.wrap>.cell:nth-child(even)>.image>img {

        border-bottom: 1.5rem solid $dark-blue;

    }

    >.wrap>.cell>.image>.-grey {

        border-bottom: 1.5rem solid $shuttle-grey !important;

    }

}