.content-banner {

    background-color: $dark-grey;
    color: lighten($red, 13%);
    padding: .5rem;
    text-transform: uppercase;
    @include font-weight(light);

    @include media-query(micro) {

        display: block;
        margin-bottom: 2rem;

    }

    @include media-query(milli) {

        padding: 1rem;
        font-size: 1.25rem;

    }

    @include media-query(kilo) {

        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

    }

    @include media-query(mega) {

        font-size: 1.5rem;

    }

    >.text {

        display: block;

    }

    >.pipe {

        display: none;

        @include media-query(kilo) {

            display: block;
            margin: 0 1rem;

        }

    }

    >.phone-numbers {

        display: flex;
        flex-wrap: wrap;

        @include media-query(nano) {

            justify-content: flex-start;

        }

    }

    >.phone-numbers>.telephone,
    .mobile {

        color: lighten($red, 13%);
        margin: 0;
        width: 100%;

        @include media-query(nano) {

            width: auto;

        }

        >a {

            color: lighten($red, 13%);

        }

    }

    >.phone-numbers .mobile {

        @include media-query(nano) {

            margin-left: 1rem;

        }

        @include media-query(mega) {

            margin-left: 1.5rem;

        }

    }

    >.crumbtrail {

        @include media-query(milli) {

            display: inline;

        }

        @include media-query(mega) {

            display: inline-flex;
            align-items: center;

        }

        @include media-query(kilo) {

            margin-left: 1rem;

        }

        >* {

            color: #FFF;
            font-size: .8rem !important;
        }

        >.separator {

            margin: 0 .5rem;

        }

    }

}