.aside {

    background-color: #FFF;
    font-size: 1.1875rem;

    @include media-query(normal) {

        min-width: 315px;
        margin-left: 2rem;

    }

    >.text {

        @include font-weight(semibold);

    }

    >.sale-text {

        color: $red;
        font-size: 1.5rem;
        margin-bottom: 0;
        padding-bottom: 1rem;

    }

}