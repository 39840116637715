//
// Global settings
//

// Box model settings
* {
    box-sizing: border-box;
}

body {
    background-color: #EBE7DB;
}

.hidden {
    display: none;
}

.hidden.-important {
    display: none !important;
}

.visible {
    display: block;
}

.visible.-important {
    display: block !important;
}

.visible-flex.-important {

    display: flex !important;

}