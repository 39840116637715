.site-header {

    width: 100%;

    .page-wrap {

        padding: 1rem;

        @include media-query(mega) {

            padding: 0;

        }

    }

    .page-wrap>.flex-wrap {

        @include media-query(mega) {

            display: flex;
            justify-content: space-between;

        }

        >.col>#logo {

            margin: 0 auto;
            text-align: center;
            display: flex;
            align-items: center;

            >a {
                display: flex;
                align-items: center;
            }

            >a>img {

                //max-width: 85%;
                //position: relative;
                //top: .5rem;

                @include media-query(nano) {

                    max-width: 100%;
                    top: .3rem;

                }

                @include media-query(milli) {

                    position: static;

                }

            }

        }

        >.col>#logo>.facebook-link {
            position: static;
            margin: 0 1rem;
            display: flex;
            align-items: center;

            @include media-query(micro) {
                margin: 0 0 0 3rem;
            }

            @include media-query(mega) {
                margin: 0 0 0 2rem;
            }

            @include media-query(giga) {
                margin: 0 0 0 3rem;
            }

            @include media-query(tera) {
                margin: 0 0 0 3.5rem;
            }

            >img {
                min-width: 40px;
                min-height: 40px;
                max-width: 40px;
                max-height: 40px;
            }
        }

        >.col>.nav-toggle {

            display: none;
            width: 40px;
            min-width: 40px;
            height: 40px;
            background-color: $shuttle-grey;
            border: 0;
            border-radius: 3px;
            cursor: pointer;

            &:hover {

                background-color: darken($shuttle-grey, 20%);

            }

            @include media-query(normal) {

                display: none !important;

            }

        }

        >.-flex {

            display: flex;
            justify-content: space-between;

        }

    }

}

.site-header.-fixed {

    @include media-query(normal) {

        position: fixed;
        top: 0;
        z-index: 1000;

    }

}