.map-wrap {

    background-color: $shuttle-grey;
    width: 100%;
    height: 200px;
    max-width: 800px;
    position: relative;

    @include media-query(nano) {

        height: 250px;

    }

    @include media-query(micro) {

        height: 300px;

    }

    @include media-query(milli) {

        height: 350px;

    }

    >.map {

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

    }

    >.map>.mapouter {
        position: relative;
        text-align: right;
        width: 100%;
        height: 100%;
        max-width: 800px;

        @include media-query(milli) {

            height: 350px;

        }
    }

    >.map>.mapouter>.gmap_canvas {
        overflow: hidden;
        background: none !important;
        width: 100%;
        height: 100%;
        max-width: 800px;

        @include media-query(milli) {

            height: 350px;

        }

        >#gmap_canvas {

            width: 100%;
            height: 100%;
            margin: 0;
            border: 0;

        }
    }

}