//
// Forms
//
// The -webkit-appearance property is needed to allow CSS styles to work in
// WebKit browsers. The property can also be set to "none", which might also
// be useful for search inputs.
//
// This resets the box model on search input elements. Alternatively, if
// support for old versions of Internet Explorer is not required, set the box
// model on all input elements to "border-box".
//
// The overflow property on the textarea element prevents the default
// scrollbars on Internet Explorer.
//
// Use ::-webkit-input-placeholder and :-moz-placeholder (separately) to style
// input placeholders.
//

button,
input,
select,
textarea {
    border-radius: 0;
    font: inherit;
    margin: 0;
}

button,
input {
    line-height: normal;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
}

button[disabled],
input[disabled] {
    cursor: default;
}

input[type="checkbox"],
input[type="radio"] {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

input[type="search"] {
    // -webkit-appearance: textfield; // cannot change border radius
    -webkit-appearance: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
}

::-ms-clear {
    display: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

textarea {
    overflow: auto;
}

input:focus,
textarea:focus {
    outline: 0;
}

:invalid {
    box-shadow: none;
}
